import React, { useEffect, useState } from 'react';
import { Box, Grid } from '@mui/material';
import { useSelector } from 'react-redux';
import cx from 'classnames';

import { useStyles } from './styles';
import { selectRssFeed } from '../../redux/slices/rssSlice';
import { capitalizeFirstLetter, removeHTMLFromString } from '../../utils/utils';

export const FeedData = () => {
  const classes = useStyles();
  const { feed } = useSelector(selectRssFeed);
  const [image, setImage] = useState();

  useEffect(() => {
    setImage(feed?.itunes?.image || feed?.image?.url);
  }, [feed]);

  return (
    feed && (
      <Grid container item md={12} alignItems="flex-start" className="register-form-row">
        <Grid container item md={3}>
          <div className={cx('register-form-block')}>
            {image && <img className={classes.image} src={image} alt={feed?.title} />}
          </div>
        </Grid>
        <Grid container item md={8} className={classes.dataSection}>
          <Grid container item md={6}>
            <Box className="label">
              <label>Podcast Name:</label>
            </Box>
            <Box pl={1} pr={2} fontWeight={600}>
              {feed?.title}
            </Box>
          </Grid>
          {feed?.itunes?.categories?.length && (
            <Grid container item md={6}>
              <Box className="label">
                <label>Categories: </label>
              </Box>
              <Box pl={1} pr={2} fontWeight={600}>
                {feed?.itunes?.categories?.join(', ')}
              </Box>
            </Grid>
          )}
          {feed?.itunes?.owner &&
            Object.entries(feed?.itunes?.owner).map(([key, value]) => (
              <Grid key={key} container item md={6}>
                <Box className="label">
                  <label>Owner {capitalizeFirstLetter(key)}: </label>
                </Box>
                <Box pl={1} pr={2} fontWeight={600}>
                  {value}
                </Box>
              </Grid>
            ))}
          <Grid container item md={6}>
            <Box className="label">
              <label>Total Episodes:</label>
            </Box>
            <Box pl={1} pr={2} fontWeight={600}>
              {feed?.items?.length}
            </Box>
          </Grid>
          <Grid container item md={12} style={{ wordBreak: 'break-word' }}>
            <Box className="label">
              <label>Description: </label>
            </Box>
            <Box fontWeight={600}>{removeHTMLFromString(feed?.description)}</Box>
          </Grid>
        </Grid>
      </Grid>
    )
  );
};
